import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { Layout } from 'layout';
import Banner from 'components/Banner';

import { NotFoundQueryResult } from './model';

import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allPageNotFound: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allPageNotFound {
        nodes {
          lang
          heroBanner {
            ...BannerType
          }
        }
      }
      pageNotFound {
        heroBanner {
          ...BannerType
        }
      }
    }
  `);

  const { heroBanner } = nodes.find((item) => item.lang === currentLangCode) || pageNotFound;
  const crumbs = [
    {
      pathname: '/',
      crumbLabel: 'Home',
    },
  ];
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  return (
    <Layout {...{ crumbsSettings }} className="not-found">
      <section id="main-content">
        <Banner {...heroBanner} />
      </section>
    </Layout>
  );
};

export default NotFoundPage;
